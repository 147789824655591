import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { UserToEdit } from '../_models/UserToEdit';
import { Observable } from 'rxjs';
import { ProjectType } from '../_models/projectType';
import { TaskTypes } from '../_models/taskTypes';
import { TaskState } from '../_models/taskState';
import { Task } from '../_models/task';
import { BoardTask } from '../_models/boardTask';
import { BoardData } from '../_models/boardData';
import { PaginatedResults } from '../_models/paginatedResults';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  saveTask(taskData) {
    return this.http.post<any>(this.baseUrl + 'tasks/create', taskData);
  }

  editSingleTask(taskData, organizationId: number) {
    taskData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'tasks/edit/single', taskData);
  }

  editTasks(taskData, projectId: number, organizationId: number) {
    taskData.userId = this.authService.currentUser.id;
    taskData.projectId = projectId;
    taskData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'tasks/edit', taskData);
  }

  deleteTask(taskId: number, projectId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        taskOwner: this.authService.decodedToken.id,
        projectId,
        organizationId
      },
    };

    return this.http.delete<any>(this.baseUrl + 'task/delete/' + taskId, options);
  }

  getProjectTasks(organizationId: number, projectId: number, perPage: number, pageNumber: number) {
    const taskData = {
      organizationId,
      ownerId: this.authService.decodedToken.id,
      projectId,
      perPage: perPage ? perPage : null,
      pageNumber: pageNumber ? pageNumber : null
    };

    return this.http.post<PaginatedResults>(this.baseUrl + 'task/get', taskData);
  }

  getPaginatedProjectTasks(projectId: number, perPage: number, pageNumber: number) {
    const userId = this.authService.decodedToken.id;
    const taskData = {
      ownerId: userId,
      projectId,
      perPage: perPage ? perPage : 0,
      pageNumber: pageNumber ? pageNumber : 0
    };

    return this.http.post<Task[]>(this.baseUrl + 'task/get', taskData);
  }

  getBoardTasks(boardDataValues: BoardData) {
    const boardData: BoardData = {
      organizationId: boardDataValues.organizationId,
      userId: this.authService.decodedToken.id,
      projectId: boardDataValues.projectId,
      taskTypeId: boardDataValues.taskTypeId,
      versionId: boardDataValues.versionId,
      selectedTags: boardDataValues.selectedTags,
      priorityId: boardDataValues.priorityId
    };
    return this.http.post<any>(this.baseUrl + 'board/getTasks', boardData);
  }

  getTaskTypes(projectId: number, organizationId: number) {
    let body = {
      userId: this.authService.currentUser.id,
      organizationId
    };
    return this.http.post<TaskTypes[]>(this.baseUrl + 'task/get/types/' + projectId, body);
  }

  saveTaskType(taskTypeData) {
    taskTypeData.userId = this.authService.currentUser.id;
    return this.http.post<any>(this.baseUrl + 'task/types/save', taskTypeData);
  }

  editTaskTypes(taskTypeData, projectId: number, organizationId: number) {
    taskTypeData.userId = this.authService.currentUser.id;
    taskTypeData.projectId = projectId;
    taskTypeData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'task/types/edit', taskTypeData);
  }

  deleteTaskType(typeId: number, projectId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        projectId,
        organizationId,
        userId: this.authService.currentUser.id
      },
    };
    return this.http.delete<any>(this.baseUrl + 'task/types/delete/' + typeId, options);
  }

  getTaskStates(projectId: number, organizationId: number) {
    let body = {
      userId: this.authService.currentUser.id,
      organizationId
    };
    return this.http.post<TaskState[]>(this.baseUrl + 'task/get/states/' + projectId, body);
  }

  saveTaskState(taskStateData) {
    taskStateData.userId = this.authService.currentUser.id;
    return this.http.post<any>(this.baseUrl + 'task/states/save', taskStateData);
  }

  editTaskStates(taskStateData, projectId: number, organizationId: number) {
    taskStateData.userId = this.authService.currentUser.id;
    taskStateData.projectId = projectId;
    taskStateData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'task/states/edit', taskStateData);
  }

  deleteTaskState(stateId: number, projectId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        projectId,
        userId: this.authService.currentUser.id,
        organizationId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'task/states/delete/' + stateId, options);
  }

  updateTaskStateOrder(body) {
    body.userId = this.authService.currentUser.id;
    return this.http.post<any>(this.baseUrl + 'taskStates/updateOrder', body);
  }

  setTaskAssignee(taskId: number, assigneeId: number, projectId: number) {
    const body = {
      taskId: taskId,
      assigneeId: assigneeId,
      userId: this.authService.currentUser.id,
      projectId: projectId
    };
    return this.http.post<any>(this.baseUrl + 'task/assignee/set', body);
  }

  getTaskAssignee(taskId: number, projectId: number) {
    const body = {
      userId: this.authService.currentUser.id,
      projectId: projectId
    };
    return this.http.post<any>(this.baseUrl + 'task/assignee/get/' + taskId, body);
  }
}
